// File generated automatically, do not modify

import axios, { AxiosResponse } from 'axios';
import { AuthInfo, LoginRequest, LoginResponse, LoginCurrentRequiredAction, LoginRequestWithPassChange, LoginActionResponse, LoginRequestWithCode, LoginRequestGenerateAlternativeOneTimePassword, ImpersonateUserRequest, RecoverPasswordStartCommand, RecoverPasswordCompleteCommand } from './models';

const staticRequestHeaders = {
  'x-api-v2': '1'
}

const paths = {
  info: 'api/auth/info',
  login: 'api/auth/login',
  getCurrentRequiredAction: 'api/Auth/GetCurrentRequiredAction',
  login_with_pass_change: 'api/auth/login_with_pass_change',
  loginWithCode: 'api/Auth/LoginWithCode',
  generateAlternativeOneTimePassword: 'api/Auth/GenerateAlternativeOneTimePassword',
  logout: 'api/auth/logout',
  impersonate: 'api/Auth/Impersonate',
  startPasswordRecovery: 'api/Auth/StartPasswordRecovery',
  completePasswordRecovery: 'api/Auth/CompletePasswordRecovery',
};

function info(): Promise<AuthInfo> {
  return axios.request<AuthInfo>({
    url: paths.info,
    method: 'get',
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

function login(data: LoginRequest): Promise<LoginResponse> {
  return axios.request<LoginResponse>({
    url: paths.login,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  }).then(res => res.data);
}

function getCurrentRequiredAction(): Promise<LoginCurrentRequiredAction> {
  return axios.request<LoginCurrentRequiredAction>({
    url: paths.getCurrentRequiredAction,
    method: 'get',
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

function login_with_pass_change(data: LoginRequestWithPassChange): Promise<LoginActionResponse> {
  return axios.request<LoginActionResponse>({
    url: paths.login_with_pass_change,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  }).then(res => res.data);
}

function loginWithCode(data: LoginRequestWithCode): Promise<LoginActionResponse> {
  return axios.request<LoginActionResponse>({
    url: paths.loginWithCode,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  }).then(res => res.data);
}

function generateAlternativeOneTimePassword(data: LoginRequestGenerateAlternativeOneTimePassword): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.generateAlternativeOneTimePassword,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  });
}

function logout(): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.logout,
    method: 'get',
    headers: staticRequestHeaders,
  });
}

function impersonate(data: ImpersonateUserRequest): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.impersonate,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  });
}

function startPasswordRecovery(data: RecoverPasswordStartCommand): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.startPasswordRecovery,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  });
}

function completePasswordRecovery(data: RecoverPasswordCompleteCommand): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.completePasswordRecovery,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  });
}

export const authService = {
  paths,
  info,
  login,
  getCurrentRequiredAction,
  login_with_pass_change,
  loginWithCode,
  generateAlternativeOneTimePassword,
  logout,
  impersonate,
  startPasswordRecovery,
  completePasswordRecovery,
};

import { useEffect, useState } from "react";
import { LogoSvgImg } from "./LogoSvgImg";
import "./LogoWatermark.scss";
import { toClassNames } from "./reactHelpers";

interface LogoWatermarkProps {
  opaque?: boolean;
  opaqueAnimated?: boolean;
  annotation?: string | undefined;
  fromTenant?: boolean;
}

export function LogoWatermark(props: LogoWatermarkProps) {
  const { opaque, opaqueAnimated, annotation, fromTenant } = props;

  const [opaqueAnimateTrigger, setOpaqueAnimateTrigger] = useState(false);
  const cls = toClassNames({
    "logo-watermark": true,
    opaque: opaque || opaqueAnimateTrigger,
  });

  useEffect(() => {
    setOpaqueAnimateTrigger(opaqueAnimated || false);
  }, [opaqueAnimated]);

  return (
    <div className={cls}>
      <div className="logo-container">
        <LogoSvgImg fromTenant={fromTenant} />
      </div>
      <div className="annotation">
        <h3>{annotation}&nbsp;</h3>
      </div>
    </div>
  );
}

import React, { Suspense } from "react";
import "./TabsView.scss";
import { combineClassNames, toClassNames } from "../utils/reactHelpers";
import { ClickableIcon, Icon } from "core/utils";
import { Text } from "core/content";
import { useTr } from "core/intl";
import { StandardErrorBoundary } from "core/utils/StandardErrorBoundary";
import { StandardLazyComponentFallback } from "core/utils/StandardLazyComponentFallback";

type TabsViewMode = "text" | "icon";
type HideMode = "to-left" | "to-right";

interface TabsViewProps {
  children: React.ReactElement<TabsItemProps> | Array<React.ReactElement<TabsItemProps> | false | null | undefined>;
  hideable?: HideMode;
  style?: React.CSSProperties;
  className?: string;
  mode?: TabsViewMode;
  width?: string;
  hidden?: boolean;
  onStartOpenTabByCode?: string;
  onHideChange?: () => any;
}

interface TabsViewState {
  activeCaption: string;
}

interface TabsItemProps {
  caption: string;
  code?: string;
  tooltip?: string;
  show?: boolean;
  children?: React.ReactNode;
  padding?: boolean;
  scrollable?: boolean;
  noTranslate?: boolean;
  onSelect?: () => any;
}

export class TabsView extends React.Component<TabsViewProps, TabsViewState> {
  constructor(props: TabsViewProps) {
    super(props);

    this.select = this.select.bind(this);

    this.state = {
      activeCaption: "",
    };
  }

  render() {
    const mode = this.props.mode || "text";
    const viewCls = combineClassNames(
      "tabs-view",
      this.props.className,
      `mode-${mode}`,
      this.props.hideable ? `hide-mode-${this.props.hideable}` : undefined,
      this.props.hidden && "hidden"
    );
    const style = this.props.style || {};

    if (this.props.hidden) {
      style.width = "48px";
    } else if (this.props.width) {
      style.width = this.props.width;
    }

    return (
      <div className={viewCls} style={style}>
        <div className="tabs-bar">
          {this.renderHideIcon()}
          {this.renderTabs()}
        </div>
        <StandardErrorBoundary category="tab" description={this.state.activeCaption}>
          <Suspense fallback={<StandardLazyComponentFallback />}>{this.renderActiveTab()}</Suspense>
        </StandardErrorBoundary>
      </div>
    );
  }

  renderHideIcon() {
    if (!this.props.hideable) return undefined;

    const symbol =
      this.props.hideable === "to-left"
        ? this.props.hidden
          ? "angleRight"
          : "angleLeft"
        : this.props.hidden
        ? "angleLeft"
        : "angleRight";

    return (
      <div className="hide-icon-tab">
        <ClickableIcon symbol={symbol} onClick={() => this.props.onHideChange && this.props.onHideChange()} />
      </div>
    );
  }

  renderShowIcon() {}

  renderTabs() {
    if (this.props.hidden) return null;

    const tabs = this.getTabsComponents();
    const result = tabs
      .filter(tab => isTabVisible(tab.props))
      .map(tab => {
        return (
          <TabsItemInternal
            key={tab.props.caption}
            activeCaption={this.state.activeCaption}
            onSelect={this.select}
            tabItem={tab.props}
          />
        );
      });

    return result;
  }

  renderActiveTab() {
    if (this.props.hidden) return null;

    const activeTab = this.getTabsComponents().find(x => x.props.caption === this.state.activeCaption);
    return activeTab;
  }

  componentDidMount() {
    const tabs = this.getTabsComponents();
    if (!tabs.length) return;

    if (this.props.onStartOpenTabByCode) {
      const tab = tabs.find(x => x.props.code === this.props.onStartOpenTabByCode);
      if (tab) {
        this.select(tab.props);
        return;
      }
    }

    this.select(tabs[0].props);
  }

  getTabsComponents() {
    return React.Children.toArray(this.props.children).filter(x => x) as React.ReactElement<TabsItemProps>[];
  }

  select(tab: TabsItemProps) {
    this.setState({
      activeCaption: tab.caption,
    });
    if (tab.onSelect) {
      tab.onSelect();
    }
  }
}

export function TabsItem(props: TabsItemProps) {
  if (!isTabVisible(props)) return null;

  const style: React.CSSProperties = {};

  if (props.padding) {
    style.padding = "16px";
    style.boxSizing = "border-box";
  }

  if (props.scrollable) {
    style.overflowY = "auto";
  }

  return (
    <div className="tab-content" style={style}>
      {props.children}
    </div>
  );
}

interface TabsItemInternalProps {
  activeCaption: string;
  tabItem: TabsItemProps;
  onSelect: (tabItem: TabsItemProps) => any;
}

function TabsItemInternal(props: TabsItemInternalProps) {
  const tr = useTr();
  const title = props.tabItem.tooltip && tr(props.tabItem.tooltip);

  return (
    <div
      title={title}
      onClick={() => props.onSelect(props.tabItem)}
      className={toClassNames({
        "tab-item": true,
        selected: props.tabItem.caption === props.activeCaption,
      })}
    >
      <TabCaption caption={props.tabItem.caption} />
      <div className="tab-active-indicator"></div>
    </div>
  );
}

function isTabVisible(props: TabsItemProps) {
  return !props.hasOwnProperty("show") || props.show;
}

function TabCaption(props: { caption: string }) {
  const { caption } = props;

  if (caption.startsWith("icon:")) {
    const iconClass = caption.replace("icon:", "");
    return <Icon symbol="rawSymbolClass" rawSymbolClass={iconClass} />;
  } else {
    return <Text>{caption}</Text>;
  }
}

import { globalTr } from "core/intl";

export function debounce<T extends Function>(fn: T, wait?: number, immediate?: boolean): T {
  var timeout: number | undefined;

  return function (this: any) {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = undefined;
      if (!immediate) {
        fn.apply(context, args);
      }
    };
    var callNow = immediate && !timeout;
    window.clearTimeout(timeout);
    timeout = window.setTimeout(later, wait);
    if (callNow) {
      fn.apply(context, args);
    }
  } as any;
}

export function px(size: number): string {
  return `${size}px`;
}

export function genericOrderCompareFn<T>(a: T, b: T) {
  return a == b ? 0 : a < b ? -1 : 1;
}

const bytesUnits = ["B", "KB", "MB", "GB"];
export function formatBytes(bytes: number): string {
  let value = bytes;
  let unitIdx = 0;

  for (let i = 1; i < bytesUnits.length; i++) {
    if (value < 1024) break;

    unitIdx = i;
    value = value / 1024;
  }

  value = Math.round(value * 10) / 10;

  return `${value} ${bytesUnits[unitIdx]}`;
}

export function formatCount(count: number | undefined): string {
  if (count === undefined) return "";

  return count.toLocaleString();
}

export function formatElapsedSeconds(elapsedSeconds: number, showSeconds = true): string {
  const days = Math.floor(elapsedSeconds / 86400);
  const hours = Math.floor(elapsedSeconds / 3600) % 24;
  const minutes = Math.floor(elapsedSeconds / 60) % 60;
  const seconds = (elapsedSeconds % 60).toString().padStart(2, "0");

  return [
    days ? `${days}d` : undefined,
    hours || days ? `${hours}h` : undefined,
    minutes || hours || days || (!elapsedSeconds && !showSeconds) ? `${minutes}min` : undefined,
    showSeconds ? `${seconds}s` : undefined,
  ]
    .filter(Boolean)
    .join(" ");
}

export function formatElapsedMs(elapsedMs: number): string {
  if (elapsedMs > 1000) {
    if (elapsedMs > 60000) {
      return formatElapsedSeconds(Math.floor(elapsedMs / 1000));
    }
    
    return `${(elapsedMs / 1000).toFixed(1)}s`;
  }

  return `${elapsedMs}ms`;
}

export function formatElapsedNanoseconds(totalNanoSeconds: number): string {
  const totalMicroSeconds = Math.floor(totalNanoSeconds / 1000);
  const totalMiliSeconds = Math.floor(totalMicroSeconds / 1000);
  const totalSeconds = Math.floor(totalMiliSeconds / 1000);

  const days = Math.floor(totalSeconds / 86400);
  const hours = Math.floor((totalSeconds % 86400) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);
  const miliSeconds = Math.floor(totalMiliSeconds % 1000);
  const microSeconds = Math.floor(totalMicroSeconds % 1000);

  const daysStr = days ? `${days}` : undefined;
  const hoursStr = hours || daysStr ? `${hours}`.padStart(days ? 2 : 1, "0") : undefined;
  const minutesStr = minutes || hoursStr ? `${minutes}`.padStart(hoursStr ? 2 : 1, "0") : undefined;
  const secondsStr = seconds || minutesStr ? `${seconds}`.padStart(2, "0") : undefined;
  const msStr = miliSeconds ? `${miliSeconds} ms` : undefined;
  const microStr = microSeconds ? `${microSeconds} us` : undefined;

  return [
    daysStr && `${daysStr} d`,
    hoursStr && `${hoursStr} h`,
    minutesStr && `${minutesStr} min`,
    secondsStr && `${secondsStr} s`,
    msStr,
    microStr,
  ]
    .filter(Boolean)
    .join(" ");
}

export function formatYesNo(value: boolean): string {
  return value ? globalTr('Tak') : globalTr('Nie');
}
import { usePendingOperation } from "core/utils";
import { useState } from "react";
import securityService from "./securityService";
import { Button, Form, SubmitButton, TextField } from "core/forms";
import { FeedbackMessage, Text } from "core/content";
import { tx } from "core/intl";
import { authService } from "apinet";

interface LoginRequiredCodeFormProps {
  onLoginActionRequired: () => any;
  onGoBackToLoginForm: () => any;
}

interface Model {
  code: string;
}

interface EmailModel {
  email: string;
}

export function LoginRequiredCodeForm(props: LoginRequiredCodeFormProps) {
  const [emailForm, setEmailForm] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailModel, setEmailModel] = useState<EmailModel>({ email: "" });
  const [model, setModel] = useState<Model>({ code: "" });
  const op = usePendingOperation();

  const sendEmail = () => {
    if (!emailModel.email) return;
    if (op.syncPending) return;

    op.handle(
      authService.generateAlternativeOneTimePassword(emailModel).then(() => {
        setEmailSent(true);
        setEmailForm(false);
        op.handle(undefined);
      })
    );
  };

  const accept = () => {
    if (op.syncPending) return;

    op.handle(
      securityService.loginWithCode(model.code).then(res => {
        if (res.requiredActions) {
          props.onLoginActionRequired();
        }
      })
    );
  };

  if (emailForm) {
    return (
      <Form
        rootComponent="form"
        model={emailModel}
        onChangeDispatch={setEmailModel}
        disabled={op.state.pending}
        onSubmit={sendEmail}
        key="emailForm"
      >
        <FeedbackMessage className="mb-8">
          <Text block>Podaj adres email powiązany z kontem, na które próbujesz się zalogować.</Text>
          <Text block>Jeżeli adres email będzie poprawny, otrzymasz wiadomość z kodem jednorazowym.</Text>
        </FeedbackMessage>
        <TextField name="email" autoFocus placeholder="Wprowadź email" />
        {op.state.error && (
          <FeedbackMessage kind="error" className="mt-8">
            {op.state.errorMessage || tx("Błąd operacji")}
          </FeedbackMessage>
        )}
        <div className="text-right">
          <SubmitButton cta big disabled={op.state.pending || !emailModel.email}>
            Wyślij kod
          </SubmitButton>
        </div>
      </Form>
    );
  }

  return (
    <Form
      rootComponent="form"
      model={model}
      onChangeDispatch={setModel}
      onSubmit={accept}
      disabled={op.state.pending}
      key="codeForm"
    >
      <FeedbackMessage className="mb-8">Wymagane jest podanie kodu jednorazowego:</FeedbackMessage>
      <TextField name="code" placeholder="Wpisz 6-cyfrowy kod" autoFocus />
      {emailSent && (
        <Text small muted>
          Jeżeli podałeś poprawny adres email otrzymasz wiadomość z dodatkowym kodem jednorazowym.
        </Text>
      )}
      {op.state.error && (
        <FeedbackMessage kind="error" className="mt-8">
          {op.state.errorMessage || tx("Błąd operacji")}
        </FeedbackMessage>
      )}
      <div className="text-right">
        {!emailSent && (
          <Button big onClick={() => setEmailForm(true)}>
            Wyślij kod na email
          </Button>
        )}
        <SubmitButton cta big disabled={op.state.pending || !model.code}>
          {op.state.pending ? tx("Logowanie...") : tx("Zaloguj")}
        </SubmitButton>
      </div>
    </Form>
  );
}

import { createContext, useContext } from "react";
import { ToastInstance, ToastModel } from "./ToastModel";

export interface ToastList {
  toasts: ToastInstance[];
}

export interface ToastManager {
  add: (model: ToastModel) => any;
  close: (id: number) => any;
  closeAll: () => any;
}

export const ToastManagerContext = createContext<ToastManager>({
  add: () => {},
  close: () => {},
  closeAll: () => {},
});

export const ToastListContext = createContext<ToastList>({
  toasts: [],
});

export function useToastManager() {
  return useContext(ToastManagerContext);
}